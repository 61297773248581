import * as React from "react"
import { graphql } from "gatsby"

import Posts from "../components/posts"

const BlogIndex = ({ data, location }) => (
  <Posts data={data} location={location} />
)

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMM.DD.YY")
          dateInt: date
          title
          description
          draft
          public
          tags
          number
        }
      }
    }
  }
`
